<template>
  <div class="sm:w-22rem">
    <div
      class="flex text-sm align-items-center justify-content-between mb-s3"
      v-if="!isNetSupported"
    >
      <InfoMessage type="danger" size="16">
        <div class="text-bolder">{{ $t('error.network.wrongNetwork') }}</div>
        {{ t('error.network.switchNetwork', { supportedNetwork }) }}
      </InfoMessage>
      <Button :label="$t('switch')" class="ml-auto button-base" @click="onSwitchNetwork" />
    </div>

    <div class="wallet-panel-data">
      <div class="flex text-sm align-items-center justify-content-between">
        <div class="caption">{{ t('wallet.yourAddress') }}</div>
        <ExternalLink :label="t('viewInExplorer')" :url="externalLink" />
      </div>

      <div class="wallet-address-panel px-s4 mt-s2">
        <div class="mr-s3">
          {{ getWalletName() }}
        </div>
        <CopyIcon :data="walletData.name" class="text-muted" />
      </div>

      <div class="flex text-sm mt-s4 justify-content-between" v-if="isNetSupported">
        <div class="text-muted">{{ t('wallet.lqfBalance') }}</div>
        <div>{{ formatBalance(nativeTokenBalance) }}</div>
      </div>

      <div class="flex text-sm mt-s2 justify-content-between" v-if="isNetSupported">
        <div class="text-muted">{{ t('wallet.currencyBalance', { currency }) }}</div>
        <div class="flex align-items-center">
          <span>{{ formatBalance(baseTokenBalance) }}</span>
          <TooltipIcon v-if="isBalanceTooltip" class="ml-s1">
            <div>
              {{ t('wrappedContract.inChain', { chain: 'Cardano' }) }}:
              <span> {{ formatBalance(baseTokenBalanceInCardano) }} </span>
            </div>
            <div>
              {{ t('wrappedContract.inChain', { chain: 'Milkomeda' }) }}:
              <span> {{ formatBalance(baseTokenBalanceInMilkomeda) }} </span>
            </div>
          </TooltipIcon>
        </div>
      </div>

      <InfoMessage
        type="warning"
        size="12"
        as-panel
        class="mt-s3"
        v-if="isBaseTokenBalanceTooLow && isNetSupported"
      >
        <div>
          <div class="text-bolder">{{ $t('wallet.lowCurrencyBalance', { currency }) }}</div>
          <div>{{ t('wallet.youNeedCurrency', { currency }) }}</div>
        </div>
      </InfoMessage>
    </div>

    <div class="flex text-sm align-items-center justify-content-between mt-s3">
      <div class="caption">{{ t('wallet.connected') }}</div>
      <div class="flex text-sm align-items-center mt-s1">
        <div class="connector-icon mr-s1">
          <img :src="walletConnector.icon" :alt="walletConnector.name" />
        </div>
        <span>{{ walletConnector.name }}</span>
      </div>
    </div>
  </div>

  <Button
    :label="t('wallet.disconnect')"
    class="w-full ml-auto button-base mt-s3"
    @click="onDisconnectWallet"
  />
</template>

<script>
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import BigNumber from 'bignumber.js';
import { NATIVE_TOKEN_SYMBOL } from '@/helpers/constants';
import CopyIcon from '@/components/icon/CopyIcon';
import ExternalLink from '@/components/ExternalLink';
import { ChainId } from '@/sdk/constants';
import { SUPPORTED_NETWORK_MODE } from '@/constants/SUPPORTED_NETWORK_MODE';
import { TRANSACTION_FEE_MINIMUM } from '@/constants/TRANSACTION_FEE_NATIVE';
import { WRAPPED_MILKOMEDA_CARDANO_NETWORK_NAME } from '@/constants/NETWORK_NAMES';
import { DEFAULT_CARDANO_CHAIN_ID } from '@/constants/DEFAULT_CARDANO_ID';
import { getScanLink } from '@/sdk/utils';
import { formatNumbersMixin } from '@/mixins/format-numbers.mixin';
import { switchNetwork } from '@/utils/blockchain.ts';
import InfoMessage from '@/components/InfoMessage';
import { SELECTED_NETWORK_NAME, DEFAULT_NETWORK_ID } from '@/helpers/networkParams.helper';
import { NETWORK_ETH_TOKEN_NAME, CURRENT_NATIVE_TOKEN_ADDRESS } from '@/helpers/network-eth-token';
import { ENABLE_FAKE_CARDANO_NETWORK } from '@/helpers/fakeCardanoNetwork';
import { useWallet } from '@/store/modules/wallet/useWallet';
import { useTokens } from '@/store/modules/tokens/useTokens';
import { useBalances } from '@/store/modules/tokens/useBalances';
import TooltipIcon from '@/components/tooltip/TooltipIcon.vue';

export default {
  name: 'WalletData',
  mixins: [formatNumbersMixin],
  components: {
    TooltipIcon,
    CopyIcon,
    ExternalLink,
    InfoMessage,
  },
  emits: ['disconnectWallet'],
  setup() {
    const {
      walletState,
      getSupportedNetworksNames,
      getFirstSupportedNetwork,
      getWalletName,
      getWalletNetwork,
    } = useWallet();
    const { getGasToken, getTokensWithFilter } = useTokens();
    const { getBalanceByToken, getBalancesWithFilter, balanceByTokenSymbolAndChainId } =
      useBalances();
    const { t } = useI18n();

    const isBalanceTooltip = computed(() => ENABLE_FAKE_CARDANO_NETWORK);
    const walletData = computed(() => {
      return walletState.isInjected
        ? {
            name: getWalletName,
            networkName: getWalletNetwork?.shortName,
            isNetworkSupported: walletState.isNetworkSupported,
          }
        : null;
    });

    return {
      walletState,
      getSupportedNetworksNames,
      getFirstSupportedNetwork,
      getGasToken,
      getTokensWithFilter,
      getBalanceByToken,
      getBalancesWithFilter,
      balanceByTokenSymbolAndChainId,
      t,
      isBalanceTooltip,
      walletData,
    };
  },
  data() {
    return {
      contractAddress: CURRENT_NATIVE_TOKEN_ADDRESS,
    };
  },
  computed: {
    walletConnector() {
      return this.walletState.wallets[SELECTED_NETWORK_NAME].connector;
    },
    nativeTokenBalance() {
      const symbol = NATIVE_TOKEN_SYMBOL;
      const rawBalance = this.getBalanceByToken(this.getTokensWithFilter({ symbol }))?.balance;
      return new BigNumber(rawBalance?.toFixed() || 0);
    },
    baseTokenBalance() {
      const rawBalance = this.getBalancesWithFilter(this.getGasToken())?.balance;
      return new BigNumber(rawBalance?.toFixed() || 0);
    },
    baseTokenBalanceInCardano() {
      const symbol = this.getGasToken().symbol;
      const rawBalance = this.balanceByTokenSymbolAndChainId(symbol, +DEFAULT_CARDANO_CHAIN_ID)
        .value?.balance;
      return new BigNumber(rawBalance?.toFixed() || 0);
    },
    baseTokenBalanceInMilkomeda() {
      const symbol = this.getGasToken().symbol;
      const rawBalance = this.balanceByTokenSymbolAndChainId(symbol, +DEFAULT_NETWORK_ID).value
        ?.balance;
      return new BigNumber(rawBalance?.toFixed() || 0);
    },
    externalLink() {
      const networkName = ENABLE_FAKE_CARDANO_NETWORK
        ? WRAPPED_MILKOMEDA_CARDANO_NETWORK_NAME
        : SELECTED_NETWORK_NAME;
      return getScanLink(
        this.walletData.name,
        'wallet',
        ChainId[`${networkName.toUpperCase()}_${SUPPORTED_NETWORK_MODE}`],
      );
    },
    isBaseTokenBalanceTooLow() {
      return this.baseTokenBalance.lt(TRANSACTION_FEE_MINIMUM);
    },
    isNetSupported() {
      return this.walletData.isNetworkSupported;
    },
    currency() {
      return NETWORK_ETH_TOKEN_NAME;
    },
    supportedNetwork() {
      return this.getSupportedNetworksNames.split(',')[0];
    },
  },
  methods: {
    onSwitchNetwork() {
      switchNetwork(this.getFirstSupportedNetwork);
    },
    onDisconnectWallet() {
      this.$emit('disconnectWallet');
    },
    getWalletName() {
      return this.walletData.name.slice(0, 16) + '...' + this.walletData.name.slice(-4);
    },
    formatBalance(tokenBalance) {
      const tokenBalanceRounded = tokenBalance.toFixed(2, BigNumber.ROUND_DOWN).replace(',', '');
      const isSmallBalance = tokenBalance.lt(0.01);
      const isZeroBalance = tokenBalance.isEqualTo(0);
      return isSmallBalance && !isZeroBalance
        ? '< 0.01'
        : this.format(tokenBalanceRounded, 'unlimited');
    },
  },
};
</script>

<style lang="scss" scoped>
.connector-icon {
  width: $font-size-sm;
  height: $font-size-sm;

  img {
    max-width: 100%;
    max-height: 100%;
  }
}
</style>
